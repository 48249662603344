import "./errorPage.scss";
import logo from "../../assets/images/logo.svg"
import mobileLogo from "../../assets/images/mobile-logo.svg"
import nopage from "../../assets/images/contact-us.jpg"
import { Link } from "react-router-dom";

export const ErrorPage = () => {
  return (
    <div className="error-page">
      <Link
        className="nologo"
          to="/"
        >
        <img className="desktop" src={logo} alt="logo"/>
        <img className="mobile" src={mobileLogo} alt="logo"/>
      </Link>
      <div className="error_wrapper">
        <Link to="/">
          <figure><img src={nopage} alt="nopage"/></figure>
          <span>Back home</span>
        </Link>
        <h1>Page not found</h1>
      </div>
    </div>
  );
};
