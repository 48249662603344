import  "./footer.scss"
import FooterLogo from "../../assets/images/footer_logo.svg"
import phone from "../../assets/images/phone.svg"
import mail from "../../assets/images/mail.svg"
import location from "../../assets/images/location.svg"
import facebook from "../../assets/images/facebook.svg"
import insta from "../../assets/images/insta.svg"
import linkedin from "../../assets/images/linkedin.svg"
import heart from "../../assets/images/heart.svg"
import { Link } from "react-router-dom"

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return(
    <>
      <footer className="footer_wrapper" id="footer">
        <div className="footer_content">
          <div className="container">
            <Link className="footer_logo" to="/"><img src={FooterLogo} alt="FooterLogo"/></Link>
            <div className="footer_links">
              <ul className="list-none links">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/practice">PRACTICE</Link></li>
                <li><Link to="/projects">PROJECTS</Link></li>
                <li><Link to="/team">TEAM</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
              <ul className="list-none info">
                <li><span><img src={phone} alt="phone"/></span><Link to="https://wa.link/h51q58">+62 823 4092 5484</Link></li>
                <li><span><img src={mail} alt="mail"/></span><Link to="mailto:info@balihomedesigns.com">info@balihomedesigns.com</Link></li>
                <li><span><img src={location} alt="location"/></span><Link to="https://maps.app.goo.gl/kcbdkdiKo9qg2xg1A" target="_blank">Ubud, Bali & Uvita, Costa Rica</Link></li>
              </ul>
              <ul className="list-none social">
                <li><Link to="https://www.facebook.com/balihomedesigns" target="_blank"><img src={facebook} alt="facebook"/></Link></li>
                <li><Link to="https://www.instagram.com/balihomedesigns/" target="_blank"><img src={insta} alt="insta"/></Link></li>
                <li><Link to="https://id.linkedin.com/company/pt-bali-home-designs" target="_blank"><img src={linkedin} alt="linkedin"/></Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <span>Copyrights © {currentYear} All Rights Reserved.</span>
            <span>Designed & Developed by <Link to="https://softenica.com/" target="_blank">Softenica Technologies</Link></span>
          </div>
        </div>
      </footer>
    </>
  )
}
