import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import angleDown from "../assets/images/angle-down.svg"
import { useEffect, useState } from "react";
import arrows from "../assets/images/left-right.svg"

import Lenis from "@studio-freight/lenis";
import { GlobalStateProvider } from "../context/GlobalStateContext";

export const UserLayout = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [buttonIsSticky, setButtonIsSticky] = useState(false);
  const [footerOffsetTop, setFooterOffsetTop] = useState(0);
  const [cursorStyle, setCursorStyle] = useState('default');

  const location = useLocation();

  useEffect(() => {
    const footer = document.querySelector("footer");
    const aboutSection = document.getElementById("aboutsection");

    if (footer) {
      setFooterOffsetTop(footer.offsetTop);
    }

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      // Check if user has scrolled past the middle of aboutsection
      if (aboutSection) {
        const aboutMiddle = aboutSection.offsetTop + aboutSection.offsetHeight / 100;
        setShowBackToTop(scrollY > aboutMiddle);
      }

      // Stop the button at the start of the footer
      if (footer) {
        const stopAtFooter = footerOffsetTop - windowHeight;
        setButtonIsSticky(scrollY < stopAtFooter);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [footerOffsetTop]);


  useEffect(() => {
    // Initialize Lenis for smooth scrolling
    const lenis = new Lenis({
      lerp: 0.1,
      smoothWheel: true,
      smoothTouch: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);


  // useEffect(() => {
  //   const cursor = document.querySelector('.custom-cursor');
  
  //   const updateCursorPosition = (e) => {
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     cursor.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  //   };
  
  //   // Attach mousemove event listener
  //   window.addEventListener('mousemove', updateCursorPosition);
  
  //   const clickableElements = document.querySelectorAll('#shiping-slide, #banner_img, #projectcursor, #project_slider');
  //   clickableElements.forEach((element) => {
  //     element.addEventListener('mouseenter', () => setCursorStyle('active'));
  //     element.addEventListener('mouseleave', () => setCursorStyle('default'));
  //   });
  
  //   const removeClickableElements = document.querySelectorAll('.swiper-button-prev, .swiper-button-next');
  //   removeClickableElements.forEach((element) => {
  //     element.addEventListener('mouseenter', () => setCursorStyle('default'));
  //     element.addEventListener('mouseleave', () => setCursorStyle('active'));
  //   });
  
  //   return () => {
  //     // Cleanup event listeners
  //     window.removeEventListener('mousemove', updateCursorPosition);
  
  //     clickableElements.forEach((element) => {
  //       element.removeEventListener('mouseenter', () => setCursorStyle('active'));
  //       element.removeEventListener('mouseleave', () => setCursorStyle('default'));
  //     });
  //   };
  // }, []);

  useEffect(() => {
    const cursor = document.querySelector('.custom-cursor');
  
    const updateCursorPosition = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      cursor.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    };
  
    const handleMouseEnter = () => setCursorStyle('active');
    const handleMouseLeave = () => setCursorStyle('default');
  
    // Attach mousemove event listener
    window.addEventListener('mousemove', updateCursorPosition);
  
    const clickableElements = document.querySelectorAll('#shiping-slide, #banner_img, #projectcursor, #project_slider');
    clickableElements.forEach((element) => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });
  
    return () => {
      window.removeEventListener('mousemove', updateCursorPosition);
  
      clickableElements.forEach((element) => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [location.pathname]); // Depend on location.pathname to re-run on route change
  
  useEffect(() => {
    setCursorStyle('default'); 
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <GlobalStateProvider>
      <div>
        <div className={`custom-cursor ${cursorStyle}`}>
          <img src={arrows} alt="Scroll Indicator" />
        </div>
        <div className="layputwrapper">
          <Header />
          <Outlet />
          <button
            className={`backtop ${showBackToTop ? "visible" : ""}`}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <img src={angleDown} alt="angleDown" />
          </button>
        </div>
        <Footer />
      </div>
    </GlobalStateProvider>
  );
};
