import  "./newsletter.scss"
import logo from "../../../assets/images/fav.svg"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function Newsletter() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <>
      <div className="newsletter_wrapper">
        <div className="container">
          <section data-aos="fade-up" data-aos-duration="3000" data-aos-delay="100">
            <h2>Subscribe to our newsletter</h2>
            <p>Stay updated with our latest newsletter release.</p>
            <form>
              <input placeholder="Email Address"/>
              <button className="simple_btn white_bg">Subscribe</button>
            </form>
          </section>
        </div>
        <img className="ab_logo" src={logo} alt="logo"/>
      </div> 
    </>
  )
}