import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Context create karna
const GlobalStateContext = createContext();

// Provider Component
export const GlobalStateProvider = ({ children }) => {
  const [bannerActive, setBannerActive] = useState(false); // Start false
  const [loading, setLoading] = useState(true);
  const [headerActive, setHeaderActive] = useState(false);
  const [visitedRoutes, setVisitedRoutes] = useState({}); // Track visited routes
  const location = useLocation(); // Route change detect karne ke liye

  useEffect(() => {
    // Jab page refresh ho, loading chalega chahe koi bhi route ho
    setLoading(true);
    setHeaderActive(false);
    setBannerActive(false);

    const loadingTimer = setTimeout(() => {
      setLoading(false);

      // Jab loading complete ho tabhi banner activate ho
      const bannerTimer = setTimeout(() => {
        setBannerActive(true);
        setVisitedRoutes((prev) => ({ ...prev, [location.pathname]: true })); // Mark route as visited
      }, 200);

      // Header ko thoda baad main show karein
      const headerTimer = setTimeout(() => {
        setHeaderActive(true);
      }, 500);

      return () => {
        clearTimeout(bannerTimer);
        clearTimeout(headerTimer);
      };
    }, 5000); // 5s ka loader

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    // Jab route change ho, sirf banner update hoga
    setBannerActive(false);
    if (!loading) {
      // setBannerActive(false);

      // Pehle ka visited route reset karna
      setVisitedRoutes((prev) => ({
        ...prev,
        [location.pathname]: false, // Current route ka banner inactive karein
      }));

      const bannerTimer = setTimeout(() => {
        setBannerActive(true);
        setHeaderActive(true);
        setVisitedRoutes((prev) => ({
          ...prev,
          [location.pathname]: true, // Mark as visited
        }));
      }, 500);

      return () => clearTimeout(bannerTimer);
    }
  }, [location.pathname, loading]);

  return (
    <GlobalStateContext.Provider value={{ bannerActive, setBannerActive, loading, headerActive }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom Hook for easy access
export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
