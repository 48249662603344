import  "./projects.scss"
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import projects from "../../projects/projectData.json";


export default function RelatedProjects(props) {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);

  return(
    <>
      <div className="projects_wrapper">
        <div className="project_title" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
          <h2>{props.projectTitle}</h2>
          <Link to="/projects" className="simple_btn white_bg">View All Projects</Link>
        </div>
        <div
          data-aos="fade-up" data-aos-offset="200" data-aos-duration="3000" data-aos-delay="200"
          className="project_sliders"
          id="projectcursor"
        >
          <Splide
            options={{
              type: 'loop',
              drag: 'free',
              focus: 'center',
              perPage: 2.5,
              autoScroll: {
                speed: 2,
                pauseOnHover: true,
              },
              arrows: false,
              pagination: false,
              gap: "105px",
              breakpoints: {
                640: {
                  perPage: 2,
                  gap    : '.7rem',
                  // height : '6rem',
                  autoScroll: {
                    speed: 1,
                  },
                },
                480: {
                  perPage: 1,
                  gap    : '.7rem',
                  // height : '6rem',
                  autoScroll: {
                    speed: 1,
                  },
                },
              },
            }}
            extensions={{ AutoScroll }}
          >
            {props.projects.map((project) => (
              <SplideSlide key={project.id}>
                <Link to={`/${project.slug}`}>
                  <div className="project_image">
                    <img src={require(`../../../assets/images/${project.projectImage}`)} alt="projects" />
                  </div>
                  <h3>{project.name}</h3>
                </Link>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div> 
    </>
  )
}