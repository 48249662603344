import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react"

export default function ContactForm() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <>
      <div className="form_wrapper">
        <div className="container">
          <h2>CONNECT WITH US TODAY!</h2>
          <section>
            <ul className="list-none flex flex-wrap">
              <li>
                <label>Name</label>
                <input type="text" placeholder="" className="form-control" />
              </li>
              <li>
                <label>Email*</label>
                <input type="email" placeholder="" className="form-control" />
              </li>
              <li>
                <label>Phone</label>
                <input type="phone" placeholder="" className="form-control" />
              </li>
              <li>
                <label>Subject</label>
                <input type="text" placeholder="" className="form-control" />
              </li>
              <li className="full">
                <label>Message*</label>
                <textarea className="form-control" placeholder=""></textarea>
              </li>
              <li className="full">
                <button className="simple_btn white_bg">Submit</button>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </>
  )
}