import { Link } from "react-router-dom"
import "./clients.scss"
import logo from "../../../assets/images/fav2.svg"
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Clients() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);

  return(
    <>
      <div id="client_wrap" className="client_wrap">
        <div className="container">
          <div className="client_title" id="client_title">
            <h2>Our Clients</h2>
            <p>Since we started in 2017, we’ve sent over 40 prefab structures and endless amounts of custom furniture to more than five countries, creating lasting connections with clients across the globe. Here’s a glimpse at some of the amazing clients we’ve worked with. </p>
            <Link to="/contact" className="simple_btn white_bg">Contact Us</Link>
          </div>
          <div className="client_list" id="client_list">
            <ul className="list-none flex flex-wrap">
              <li>
                <h3>Nayara Bocas Del Toro</h3>
                <Link to="https://nayarabocasdeltoro.com/" target="_blank">nayarabocasdeltoro.com</Link>
              </li>
              <li>
                <h3>Uvita Bali Bosque</h3>
                <Link to="https://www.uvitabalibosque.com/" target="_blank">uvitabalibosque.com</Link>
              </li>
              <li>
                <h3>Bali Villa Jungle Paradise</h3>
                <Link to="https://www.airbnb.com/rooms/642033897110191203?guests=1&adults=1&s=67&unique_share_id=ffe8d939-2f0b-47e9-a452-3082dbc4a3b2" target="_blank">airbnb.com/rooms/</Link>
              </li>
              <li>
                <h3>Selva Armonia</h3>
                <Link to="https://www.selvaarmonia.com/" target="_blank">selvaarmonia.com</Link>
              </li>
              <li>
                <h3>Atmosa</h3>
                <Link to="https://www.atmosa.life/" target="_blank">atmosa.life</Link>
              </li>
              <li>
                <h3>Luxury Jungle Haven</h3>
                <Link to="https://www.airbnb.com/rooms/872391029888243878?check_in=2023-10-13&check_out=2023-10-18&guests=1&adults=1&s=67&unique_share_id=1d3f8a47-4cc0-45b0-ad39-8c4dce9aec4d" target="_blank">airbnb.com/rooms/</Link>
              </li>
              <li>
                <h3>Project 369</h3>
                <Link to="/project-369">Private Client</Link>
              </li>
              <li>
                <h3>Project Octagon</h3>
                <Link to="/octagon-villa-project">Private Client</Link>
              </li>
              <li>
                <h3>Triangle project</h3>
                <Link to="/triangle-project">Private Client</Link>
              </li>
              <li>
                <h3>Kai, Indonesia</h3>
                <Link to="/kai-indonesia">Private Client</Link>
              </li>
            </ul>
          </div>
        </div>
        <img className="ab_logo" src={logo} alt="logo"/>
      </div>
    </>
  )
}