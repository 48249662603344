import Philosophy from "./philosophy/Philosophy";
import TeamBanner from "./team-banner/TeamBanner";
// import TeamMembers from "./team-members/TeamMembers";
import logo from "../../assets/images/fav2.svg"
import { Helmet } from "react-helmet-async";
import TeamMembersTwo from "./team-members/TeamMembersTwo";

export default function Team() {
  return(
    <>
      <Helmet>
        <title>Bali Home Designs | Team</title>
      </Helmet>
      <div className="team_wrapper_all" id="team_wrapper">
        <TeamBanner/>
        <Philosophy/>
        {/* <TeamMembers/> */}
        <TeamMembersTwo/>
        <img className="ab_logo" src={logo} alt="logo"/>
      </div>
    </>
  )
}