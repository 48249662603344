import "./teamMembers.scss"
import team1 from "../../../assets/images/team3.jpg"
import team2 from "../../../assets/images/team2.jpg"
import team3 from "../../../assets/images/team1.jpg"
import team4 from "../../../assets/images/team4.jpg"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom"

gsap.registerPlugin(ScrollTrigger);

export default function TeamMembersTwo() {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedImage, setSelectedImage] = useState(team1);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const animationRef = useRef(null);
  const location = useLocation();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);  // You can adjust the width based on your design needs
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setSelectedMember(false);
    setTimeout(() => {
      setIsActive(false);
    }, 0);
  };
  
  useEffect(() => {
    if (selectedMember) {
      setIsActive(true);
    }
    else {
      setSelectedMember(false);
      setTimeout(() => {
        setIsActive(false);
      }, 500);
    }
  }, [selectedMember]);
  
  useEffect(() => {
  const header = document.querySelector(".header_wrapper");
  if (selectedMember) {
    header?.classList.add("member_active_header");
  } else {
    header?.classList.remove("member_active_header");
  }
}, [selectedMember]);


  const teamMembers = [
    {
      teamImage: team1,
      name: "Rick Beunk",
      position: "Founder",
      description: [
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      ]
    },
    {
      teamImage: team2,
      name: "Alexia Maurice",
      position: "Founder",
      description: [
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      ]
    },
    {
      teamImage: team3,
      name: "Dean Creer",
      position: "General Manager",
      description: [
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
      ]
    }
  ];


  // const rightSectionHeight = document.querySelector("#rightSection")?.offsetHeight || 0;

  //     let endOffset;
  //     if (window.innerWidth > 1200) {
  //       endOffset = rightSectionHeight - 500; // Large screens
  //     } else if (window.innerWidth > 767) {
  //       endOffset = rightSectionHeight - 500; // Tablets
  //     } else {
  //       endOffset = rightSectionHeight - 10; // Small screens (optional)
  //     }

  useEffect(() => {
    // Refresh ScrollTrigger when route changes
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 500); // Add small delay to avoid race condition
  }, [location.pathname]); // Run on route change

  // useLayoutEffect(() => {
  //   if (isMobile) return;

  //   // Cleanup previous animations
  //   if (animationRef.current) {
  //     animationRef.current.revert();
  //     ScrollTrigger.getAll().forEach((t) => t.kill()); // Kill all triggers
  //   }

  //   animationRef.current = gsap.context(() => {

  //     gsap.to("#leftSection", {
  //       scrollTrigger: {
  //         trigger: "#leftSection",
  //         start: "bottom 97%",
  //         end: () => `+=${document.querySelector("#rightSection").offsetHeight - 465}`,
  //         // end: `+=${endOffset}`,
  //         scrub: true,
  //         pin: true,
  //         pinSpacing: false,
  //         markers: false,
  //       }
  //     });

  //     gsap.to("#rightSection", {
  //       scrollTrigger: {
  //         trigger: "#rightSection",
  //         start: "top center",
  //         end: "bottom top",
  //         scrub: true,
  //         markers: false,
  //         onEnter: () => gsap.to("#rightSection", { y: 0, duration: 1 }),
  //         onLeaveBack: () => gsap.to("#rightSection", { y: 0, duration: 1 })
  //       }
  //     });

  //     ScrollTrigger.refresh();
  //   });

  //   return () => {
  //     if (animationRef.current) {
  //       animationRef.current.revert(); // Cleanup animations on unmount
  //       ScrollTrigger.getAll().forEach((t) => t.kill()); // Ensure triggers are removed
  //     }
  //   };
  // }, [isMobile, location.pathname]);

  return(
    <>
      <div className={`team_member_wrap ${selectedMember ? "active" : ""}`}>
        <div className="container">
          <div className={`left_title_section ${selectedMember ? "active" : ""}`} id="leftSection">
            <h2>
              <span>United by craftmanship,</span>
              <span>driven by passion,</span>
              <span>We are Bali Home Designs</span>
            </h2>
            {/* <figure>
              <img src={selectedImage} alt="team" />
            </figure> */}
          </div>
          <div className="right_team_section" id="rightSection">
            <ul className="list-none flex flex-wrap">
              {teamMembers.map((member, index) => (
                <li
                  key={index}
                  onMouseEnter={() => setSelectedImage(member.teamImage)}
                  onMouseLeave={() => setSelectedImage(member.teamImage)}
                  // onClick={() => setSelectedMember(member)}
                >
                  <figure>
                    <img src={member.teamImage} alt="team" />
                  </figure>
                  <section>
                    <h3>{member.name}</h3>
                    <span>{member.position}</span>
                  </section>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={`menu_wrapper ${isActive ? "active": ""}`}>
        <button
          onClick={handleClose}
          className={`othersection menu_btn ${isActive ? "active": ""}`}
        />
      </div>
      <div className={`modal-overlay ${selectedMember ? "active" : ""}`} onClick={() => setSelectedMember(null)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {selectedMember && (
            <>
              <h2>{selectedMember.name}</h2>
              <span>{selectedMember.position}</span>
              {selectedMember.description &&
                (Array.isArray(selectedMember.description) ? 
                  selectedMember.description.map((para, idx) => <p key={idx}>{para}</p>) : 
                  <p>{selectedMember.description}</p>
                )
              }
            </>
          )}
        </div>
      </div>

    </>
  )
}