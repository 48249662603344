import "./philosophy.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Philosophy() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <>
      <div className="philosophy_about">
        <div className="container flex">
          <small>Our Philosophy</small>
          <section>
            <p>At the heart of our work is the perfect synergy between global standards of excellence and Indonesia's revered tradition of skilled carpentry. Based in Bali, we bring together the precision and vision of our international management team with the exceptional craftsmanship of local artisans to create high-end prefab wooden villas that redefine affordable luxury living.</p>
            <p>We are deeply committed to preserving the rich heritage of ancient Indonesian woodworking, seamlessly integrating it with modern design and innovation. Each villa we craft is a testament to this harmonious blend—a celebration of timeless artistry and contemporary sophistication.</p>
            <Link to="/contact" className="simple_btn">Contact Us</Link>
          </section>
        </div>
      </div>
    </>
  )
}