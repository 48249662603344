import {ParallaxProvider, Parallax } from "react-scroll-parallax"
import  "./getInTouch.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function GetInTouch() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <ParallaxProvider>
      <div className="get_in_touch_wrap" id="get_in_touch">
        <Parallax speed={-30}>
          <div className="paralex_bg"/>
        </Parallax>
        <section data-aos="fade-up" data-aos-delay="100">
          <h2>GET IN TOUCH</h2>
          <p>Feel free to reach out and share your vision with us. Together, let's build something remarkable that reflects your unique style and aspirations.</p>
          <Link to="/contact" className="simple_btn">Contact us</Link>
        </section>
      </div>
    </ParallaxProvider>
  )
}