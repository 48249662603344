import {ParallaxProvider, Parallax } from "react-scroll-parallax"
import  "./about.scss"
import about1 from "../../../assets/images/about1.jpg"
import aboutlap1 from "../../../assets/images/aboutlap1.jpg"
import about2 from "../../../assets/images/about2.jpg"
import aboutlap2 from "../../../assets/images/aboutlap2.jpg"
import { useEffect } from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom"

export default function About() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <ParallaxProvider>
      <div className="about_wrapper" id="aboutsection">
        <div className="container">
          <div className="about_content">
            <section data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">
              <h2>Where Indonesian Craftsmanship Meets Modern Design</h2>
              <p>Bali Home Designs is a boutique prefab villa design studio & manufacturer specializing in luxurious, sustainable prefab villas. Based in Bali, we design and build custom prefab villas that blend traditional Indonesian craftsmanship with modern design, ideal for luxury resorts or private retreats.</p>
              <p>Focusing on premium materials and exceptional woodcarving, we offer unique, elegant solutions that combine timeless beauty with contemporary efficiency in every project.</p>
              <p>Welcome to Bali Home Designs — where Indonesian Craftsmanship Meets Modern Design</p>
              <Link to="/practice" className="simple_btn white_bg">PRACTICE</Link>
            </section>
            <figure data-aos="fade-left" data-aos-offset="200" data-aos-delay="100">
              <Parallax speed={-30}>
                <img className="desktop" src={about1} alt="about1"/>
                <img className="laptop" src={aboutlap1} alt="about1"/>
              </Parallax>
            </figure>
          </div>
          <div className="about_content about_content2">
            <figure data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">
              <Parallax speed={-30}>
                <img className="desktop" src={about2} alt="about2"/>
                <img className="laptop" src={aboutlap2} alt="about1"/>
              </Parallax>
            </figure>
            <section data-aos="fade-left" data-aos-offset="200" data-aos-delay="100">
              <h3>Dreaming Affordable Luxury</h3>
              <p>At Bali Home Designs, we turn our clients' dreams into reality, blending traditional Indonesian craftsmanship with modern design. Our luxury prefab villas are designed to be functional, sustainable, and culturally authentic, offering a unique expression of affordable luxury.</p>
              <h3>Crafting Affordable Luxury</h3>
              <p>Once the designs are finalized, we bring them to life in our Bali factory. Using sustainable materials, our expert artisans create each prefab villa with precision and care, ensuring the highest standards of quality & durability - crafting affordable luxury.</p>
              <h3>Building Affordable Luxury</h3>
              <p>From our factory to your destination, we make installation effortless. Each villa is shipped with a detailed manual for easy reassembly, ensuring a smooth construction process anywhere in the world. For projects in Costa Rica, our skilled team can oversee the entire process, from handling logistics and final assembly, building affordable luxury.</p>
            </section>
          </div>
        </div>
      </div> 
    </ParallaxProvider>
  )
}