import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Link } from "react-router-dom";
import projects from "../../projects/projectData.json";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ProjectSlider() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    import("../../projects/projectData.json")
      .then((data) => setProjects(data.default || data))
      .catch((error) => console.error("Error loading projects:", error));
  }, []);


  return(
    <>
      <div className="project_slider_section" id="project_slider" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
        <Splide
          options={{
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 3.5,
            autoScroll: {
              speed: 1,
              pauseOnHover: true,
              pauseOnFocus: true,
            },
            arrows: false,
            pagination: false,
            gap: "20px",
            breakpoints: {
              640: {
                perPage: 2,
                autoScroll: {
                  speed: 1,
                },
              },
              480: {
                perPage: 2,
                autoScroll: {
                  speed: 1,
                },
              },
            },
          }}
          extensions={{ AutoScroll }}
        >
          {projects.length > 0 ? (
            projects.map((project) => (
              <SplideSlide key={project.id}>
                <Link to={`/${project.slug}`}>
                  <img src={require(`../../../assets/images/${project.projectImage}`)} alt="projects"/>
                  <h4>{project.name}</h4>
                </Link>
              </SplideSlide>
            ))
          ) : (
            <p>Loading projects...</p>
          )}
        </Splide>
      </div>
    </>
  )
}