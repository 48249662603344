import { Helmet } from "react-helmet-async";
import Clients from "./clients/Clients";
import OurProcess from "./our-process/OurProcess";
import PracticeAbout from "./practice-about/PracticeAbout";
import PracticeBanner from "./practice-banner/PracticeBanner";
import OurProcessSecond from "./our-process/OurProcessSecond";
import OurConstruction from "./our-process/OurConstruction";

export default function Practice() {
  return(
    <>
      <Helmet>
        <title>Bali Home Designs | Practice</title>
      </Helmet>
      <PracticeBanner/>
      <PracticeAbout/>
      <OurProcess/>
      <Clients/>
      <OurConstruction/>
      <OurProcessSecond/>
    </>
  )
}