import { Link } from "react-router-dom"
import logo from "../../assets/images/fav.svg"
import "./contact.scss"
import facebook from "../../assets/images/facebook.svg"
import insta from "../../assets/images/insta.svg"
import linkedin from "../../assets/images/linkedin.svg"
import contactimage from "../../assets/images/contact-us.jpg"
import { Helmet } from "react-helmet-async"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react"
import ContactForm from "./ContactForm"

export default function Contact() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <>
      <Helmet>
        <title>Bali Home Designs | Contact</title>
      </Helmet>
      <div className="contact_wrapper_all">
        <div className="container">
          <section data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">
            <span>Contact page</span>
            <h2>We’d love to hear from you. <br/>Get in touch.</h2>
            <img src={contactimage} alt="contactimage"/>
          </section>
          <div className="contact_details" data-aos="fade-left" data-aos-offset="200" data-aos-delay="100">
            <ul className="list-none">
              <li>
                <h3>Your prefab project</h3>
                <span>To discuss your prefab project other business opportunities, please reach out.</span>
                <Link to="mailto:info@balihomedesigns.com" target="_blank">info@balihome<br/>designs.com</Link>
              </li>
              <li>
                <h3>Job opportunities</h3>
                <span>Want to work with Bali Home Designs? Please feel free to contact us.</span>
                <Link to="mailto:talent@balihomedesigns.com" target="_blank">talent@balihome<br/>designs.com</Link>
              </li>
              <li>
                <h3>Press</h3>
                <span>For press inquiries and other collaborations. </span>
                <Link to="mailto:press@balihomedesigns.com" target="_blank">press@balihome<br/>designs.com</Link>
              </li>
              <li className="so_links">
                <h3>Follow us</h3>
                <Link to="https://www.facebook.com/balihomedesigns" target="_blank"><img src={facebook} alt="facebook"/></Link>
                <Link to="https://www.instagram.com/balihomedesigns/" target="_blank"><img src={insta} alt="insta"/></Link>
                <Link to="https://id.linkedin.com/company/pt-bali-home-designs" target="_blank"><img src={linkedin} alt="linkedin"/></Link>
              </li>
              <li>
                <h3>Visit us</h3>
                <span>To visit our factory in Ubud, Bali or visit our projects in Costa Rica or elsewhere, feel free to send us an email.</span>
                <Link to="mailto:info@balihomedesigns.com" target="_blank">info@balihome<br/>designs.com</Link>
              </li>
            </ul>
          </div>
        </div>
        <ContactForm/>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63136.5523898542!2d115.22483938183672!3d-8.496024093967396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23d739f22c9c3%3A0x54a38afd6b773d1c!2sUbud%2C%20Gianyar%20Regency%2C%20Bali%2C%20Indonesia!5e0!3m2!1sen!2s!4v1740722917547!5m2!1sen!2s"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
        <img className="ab_logo" src={logo} alt="logo"/>
      </div>
    </>
  )
}