import "./projects.scss"
import logo from "../../assets/images/fav.svg"
import angleDown from "../../assets/images/downarrow.svg"
import { Link } from "react-router-dom"
import projects from "./projectData.json";
import { useGlobalState } from "../../context/GlobalStateContext";
import Preloader from "../../components/preloader/Preloader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

export default function Projects() {
  const { loading, headerActive } = useGlobalState();
  
  const [bannerActive, setBannerActive] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footer"); // Footer ko select karna
      if (footer) {
        const footerTop = footer.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        setIsFooterVisible(footerTop < windowHeight); // Jab footer visible ho jaye
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  useEffect(() => {
    if (!loading) {
        const timer = setTimeout(() => {
            setBannerActive(true);
        }, 100); // Delay to allow the fade effect to be visible

        return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [loading]);

  const handleRegionChange = (region) => {
    setFade(true); // Start fade-out effect
    setTimeout(() => {
      setSelectedRegion(region);
      setFilteredProjects(
        region === "All"
          ? projects
          : projects.filter((project) => project.region === region)
      );
      setFade(false); // Start fade-in effect
    }, 300); // Delay for smooth transition
    setOpenDrop(false);
  };

  return(
    <>
      <Helmet>
        <title>Bali Home Designs | Projects</title>
      </Helmet>
      {loading && <Preloader />}
      <div className="project_list_highmount">
        <div className={`project_head ${headerActive ? "show": ""} ${openDrop ? "active" : ""} ${isFooterVisible ? "dropoff" : ""}`}>
          <button onClick={()=>setOpenDrop(!openDrop)}>Region <img src={angleDown} alt="angleDown"/></button>
          <ul className="list-none">
            {["All", "Costa Rica", "Panama", "Indonesia"].map((region) => (
              <li key={region}>
                <span
                  className={selectedRegion === region ? "selected" : ""}
                  onClick={() => handleRegionChange(region)}
                >
                  {region}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className={`project_list_wrapper ${bannerActive ? "active": ""} ${fade ? "fade" : ""}`}>
          <ul className="list-none flex flex-wrap">
            {filteredProjects.map((project) => (
              <li key={project.id}>
                <Link to={`/${project.slug}`}>
                  <figure>
                    <img src={require(`../../assets/images/${project.projectImage}`)} alt="projects"/>
                  </figure>
                  <h2>{project.name}</h2>
                </Link>
              </li>
            ))}
          </ul>
          <img className="ab_logo" src={logo} alt="logo"/>
        </div>
      </div>
    </>
  )
}
