import  "./header.scss"
import logo from "../../assets/images/logo.svg"
import mobileLogo from "../../assets/images/mobile-logo.svg"
// import plus from "../../assets/images/plus.svg"
// import minus from "../../assets/images/minus.svg"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import OutsideClickHandler from 'react-outside-click-handler';
import facebook from "../../assets/images/facebook.svg"
import insta from "../../assets/images/insta.svg"
import linkedin from "../../assets/images/linkedin.svg"
import { useGlobalState } from "../../context/GlobalStateContext"
import { useLocation } from "react-router-dom";

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);
  // const [openDropdown, setOpenDropdown] = useState(false);
  const location = useLocation();

  const{headerActive} = useGlobalState();

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  // const handleDropdownToggle = () => {
  //   setOpenDropdown(!openDropdown);
  // };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  
    return () => {
      document.body.classList.remove("menu-open");
    };
  }, [openMenu]);


// useEffect(() => {
//   const header = document.querySelector(".header_wrapper");
//   const sectionIds = ["section4", "titleHead", "processDetail", "inner_banner_image", "get_in_touch", "home", "banner", "banner_img"];
//   const scrollThreshold = 100; // Scroll limit for class toggle
//   const offset = 25; // Offset value (adjust as needed)

//   const handleScroll = () => {
//       let isActive = window.scrollY <= scrollThreshold;

//       sectionIds.forEach((id) => {
//           const section = document.getElementById(id);
//           if (section) {
//               const rect = section.getBoundingClientRect();
//               if (rect.top <= offset && rect.bottom > offset) {
//                   isActive = true;
//               }
//           }
//       });

//       if (isActive) {
//           header.classList.add("bg_change");
//       } else {
//           header.classList.remove("bg_change");
//       }
//   };

//   window.addEventListener("scroll", handleScroll);
//   handleScroll(); // Initial check

//   return () => {
//       window.removeEventListener("scroll", handleScroll);
//   };
// }, []);

useEffect(() => {
  const header = document.querySelector(".header_wrapper");
  const sectionIds = [
    "section4",
    "titleHead",
    "titleHeadConstruction",
    "titleHeadSecond",
    "processDetail",
    "processDetailConstruction",
    "processDetailSecond",
    "processDetailImage",
    "inner_banner_image",
    "get_in_touch",
    "home",
    "banner",
    "banner_img",
    // "videowrap"
  ];
  const scrollThreshold = 100; // Scroll limit for class toggle
  const offset = 10; // Offset value (adjust as needed)

  const handleScroll = () => {
    if (location.pathname === "/team") {
      header.classList.add("blackeffect");
      return;
    }

    let isActive = window.scrollY <= scrollThreshold;
    let isLogoEffectActive = false;
    let isBlackEffectActive = false;

    sectionIds.forEach((id) => {
      const section = document.getElementById(id);
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= offset && rect.bottom > offset) {
          isActive = true;
          if (id === "section4" || id === "get_in_touch") {
            isLogoEffectActive = true;
          }
          if (id === "processDetail" || id === "processDetailSecond" || id === "processDetailConstruction" || id === "processDetailImage" || id === "team_wrapper") {
            isBlackEffectActive = true;
          }
        }
      }
    });

    if (isActive) {
      header.classList.add("bg_change");
    } else {
      header.classList.remove("bg_change");
    }

    if (isLogoEffectActive) {
      header.classList.add("logoeffect");
    } else {
      header.classList.remove("logoeffect");
    }

    if (isBlackEffectActive) {
      header.classList.add("blackeffect");
    } else {
      header.classList.remove("blackeffect");
    }
  };

  window.addEventListener("scroll", handleScroll);
  handleScroll(); // Initial check

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, [location.pathname]);

  return(
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenMenu(false);
        }}
      >
      <header className={`header_wrapper bg_change ${headerActive ? "active": ""} ${openMenu ? "openmenu": ""}`}>
        <div className="container fluid flex">
          <Link
            to="/"
          >
            <img className="desktop" src={logo} alt="logo"/>
            <img className="mobile" src={mobileLogo} alt="logo"/>
          </Link>
          <button onClick={handleMenuToggle} className={`menu_btn ${openMenu ? "active": ""}`}/>
        </div>
      </header>
      
      <div onClick={()=>setOpenMenu(false)} className={`navigation_wrap ${openMenu ? "active": ""}`}>
        <div className="navigation_links">
          <ul className="list-none">
            <li><Link onClick={()=>setOpenMenu(false)} to="/"><span>Home</span></Link></li>
            {/* <li><Link onClick={()=>setOpenMenu(false)} to="#"><span>Practice</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="#"><span>Projects</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="#"><span>Team</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="#"><span>Contact</span></Link></li> */}
            <li><Link onClick={()=>setOpenMenu(false)} to="/practice"><span>Practice</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="/projects"><span>Projects</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="/team"><span>Team</span></Link></li>
            <li><Link onClick={()=>setOpenMenu(false)} to="/contact"><span>Contact</span></Link></li>
            {/* <li>
              <Link onClick={()=>setOpenMenu(false)} to="#">Fully prefab projects</Link>
              <button
                onClick={handleDropdownToggle}
                aria-label="Toggle dropdown"
              >
                <img src={openDropdown ? minus : plus} alt="Toggle" />
              </button>
              <div className={`nav_dropdown ${openDropdown ? "active" : ""}`}>
                <Link onClick={()=>setOpenMenu(false)} to="#">BOCAS BALI PROJECT</Link>
                <Link onClick={()=>setOpenMenu(false)} to="#">UVITA BALI BOSQUE</Link>
              </div>
            </li> */}
          </ul>
          <div className="bottom_area">
            <span><small>Follow us on</small></span>
            <div className="flex">
              <Link to="https://www.facebook.com/balihomedesigns" target="_blank"><img src={facebook} alt="facebook"/></Link>
              <Link to="https://www.instagram.com/balihomedesigns/" target="_blank"><img src={insta} alt="insta"/></Link>
              <Link to="https://id.linkedin.com/company/pt-bali-home-designs" target="_blank"><img src={linkedin} alt="linkedin"/></Link>
            </div>
          </div>
        </div>
      </div>
      </OutsideClickHandler>
    </>
  )
}