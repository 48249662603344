import {
Route,
createBrowserRouter,
// createHashRouter,
createRoutesFromElements,
RouterProvider,
} from "react-router-dom";
import { UserLayout } from "./UserLayout";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import Home from "../pages/homepage/Home";
import Projects from "../pages/projects/Projects";
import ProjectDetail from "../pages/projects/ProjectDetail";
import Team from "../pages/team/Team";
import Contact from "../pages/contact/Contact";
import { HelmetProvider } from "react-helmet-async";
import Practice from "../pages/practice/Practice";

export const MainRouting = () => {
const router = createBrowserRouter (
  createRoutesFromElements (
    <>
      <Route path="/" errorElement={<ErrorPage />} element={<UserLayout />}>
        <Route index element={<Home />}></Route>
        <Route path="/practice" element={<Practice />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route path="/:projectSlug" element={<ProjectDetail />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Route>
    </>
  )
)
return (
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
);
}