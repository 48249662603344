import "./practiceAbout.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function PracticeAbout() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  return(
    <>
      <div className="practice_about">
        <div className="container flex" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
          <small>About Us</small>
          <section>
            <p>Bali Home Designs is a boutique prefab design studio and manufacturer dedicated to creating luxurious, sustainable prefab living spaces that seamlessly blend Indonesian craftsmanship with modern design. Based in the heart of Bali, we specialize in designing and building prefabricated villas that elevate any environment, from luxury resorts to private retreats.</p>
            <p>We bring together the time-honoured traditions of Indonesian craftsmanship and the cutting-edge principles of modern design to create luxury prefab villas and bespoke furniture that feel both timeless and contemporary.</p>
            <p>With a focus on sustainable materials and impressive woodcarving, we offer clients the unique opportunity to experience both elegance and efficiency in every piece we create.</p>
            <Link to="/contact" className="simple_btn white_bg">Contact Us</Link>
          </section>
        </div>
      </div>
    </>
  )
}