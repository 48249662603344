import {ParallaxProvider, Parallax } from "react-scroll-parallax"
import  "./banner.scss"
import poster from "../../../assets/images/banner-image.jpg"
import { useEffect, useLayoutEffect, useState } from "react";
import Preloader from "../../../components/preloader/Preloader";
import { gsap } from "gsap";
import { useGlobalState } from "../../../context/GlobalStateContext";


const words = ["Dreaming", "Crafting", "Building"];


export default function Banner() {
  const[bannerActive, setBannerActive] = useState(false);
  const { loading } = useGlobalState();
  const [index, setIndex] = useState(0);

  useLayoutEffect(() => {
    const element = document.querySelector(".changing-word");
    if (!element) return; // Stop execution if element doesn't exist
  
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        gsap.to(element, {
          y: "-100%",
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            setIndex((prev) => (prev + 1) % words.length);
            gsap.fromTo(
              element,
              { y: "100%", opacity: 0 },
              { y: "0%", opacity: 1, duration: 0.5 }
            );
          },
        });
      }, 3000);
  
      return () => clearInterval(interval);
    }, 6000);
  
    return () => clearTimeout(timeout);
  }, []);
  

  useEffect(() => {
    if (!loading) {
      const bannerTimer = setTimeout(() => {
        setBannerActive(true); // Banner becomes active after loading
      }, 200); // Adjust this to control when the banner becomes visible after loader

      return () => clearTimeout(bannerTimer);
    }
  }, [loading]);
  


  return(
    <>
    {loading ? <Preloader /> : ""}
    <ParallaxProvider>
      <div className={`banner_wrapper ${bannerActive ? "active": ""}`} id="home">
        <div className="banner_slides">
          <Parallax speed={-30}>
            <video className="paralex_bg" muted loop autoPlay playsInline poster={poster}>
              <source src="https://www.balihomedesigns.com/videos/banner-video.mp4" type="video/mp4"/>
            </video>
          </Parallax>
          <div className="banner_title">
            <h1>
              <span className="changing_wrap"><span className="changing-word">{words[index]}</span></span> affordable luxury
            </h1>
          </div>
          <div className="transparent_layer"/>
        </div>
      </div>
    </ParallaxProvider>
    </>
  )
}