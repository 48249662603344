import React from 'react';
import loaderGif from "../../assets/images/loader.gif"

const Preloader = () => {
  return (
    <div className="preloader">
        <img src={loaderGif} alt="loaderGif" />
    </div>
  );
};

export default Preloader;
