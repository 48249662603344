import  "./shiping.scss"
import  "../presence/presence.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {FreeMode, Autoplay, Navigation } from 'swiper/modules';
import slide1 from "../../../assets/images/slide1.jpg"
import slide3 from "../../../assets/images/slide3.jpg"
import slide5 from "../../../assets/images/slide5.jpg"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Shiping() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const animationRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1050);  // You can adjust the width based on your design needs
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


    const slideContent = [
      {
        title: "In-House from Start to Finish",
        description: [
          "We manage every step of the process in-house, from initial design and prefabrication to shipping and on-site reassembly. This all-inclusive approach guarantees a seamless experience and exceptional results for every prefab project."
        ],
        image: slide1,
      },
      {
        title: "Luxury Resorts & Private Retreats",
        description: [
          "Our specialty lies in creating bespoke prefab villas tailored to luxury resorts and private retreats. We understand the importance of elegance, comfort, and harmony with nature, delivering designs that elevate every space.",
        ],
        image: slide5,
      },
      {
        title: "Custom or Standard Designs",
        description: [
          "Every project is unique, which is why we offer the flexibility of custom or standard designs. Whether for a luxury resort, private retreat, or personal villa, our designs are crafted to elevate the experience of any space.",
        ],
        image: slide3,
      },
      {
        title: "In-House from Start to Finish",
        description: [
          "We manage every step of the process in-house, from initial design and prefabrication to shipping and on-site reassembly. This all-inclusive approach guarantees a seamless experience and exceptional results for every prefab project."
        ],
        image: slide1,
      },
      {
        title: "Luxury Resorts & Private Retreats",
        description: [
          "Our specialty lies in creating bespoke prefab villas tailored to luxury resorts and private retreats. We understand the importance of elegance, comfort, and harmony with nature, delivering designs that elevate every space.",
        ],
        image: slide5,
      },
      {
        title: "Custom or Standard Designs",
        description: [
          "Every project is unique, which is why we offer the flexibility of custom or standard designs. Whether for a luxury resort, private retreat, or personal villa, our designs are crafted to elevate the experience of any space.",
        ],
        image: slide3,
      },
    ];

    useEffect(() => {
      AOS.init({
        duration: 1200,
        once: true
      });
    }, []);


    useEffect(() => {
      // Refresh ScrollTrigger when route changes
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500); // Add small delay to avoid race condition
    }, [location.pathname]); // Run on route change
  
    useLayoutEffect(() => {
      if (isMobile) return;
  
      // Cleanup previous animations
      if (animationRef.current) {
        animationRef.current.revert();
        ScrollTrigger.getAll().forEach((t) => t.kill()); // Kill all triggers
      }
  
      animationRef.current = gsap.context(() => {
        gsap.to("#section3", {
          scrollTrigger: {
            trigger: "#section3",
            start: "bottom bottom",
            end: () => `+=${document.querySelector("#section4").offsetHeight}`,
            scrub: true,
            pin: true,
            pinSpacing: false,
            markers: false,
          }
        });
  
        gsap.to("#section4", {
          scrollTrigger: {
            trigger: "#section4",
            start: "top center",
            end: "bottom top",
            scrub: true,
            markers: false,
            onEnter: () => gsap.to("#section4", { y: 0, duration: 1 }),
            onLeaveBack: () => gsap.to("#section4", { y: 0, duration: 1 })
          }
        });
  
        ScrollTrigger.refresh();
      });
  
      return () => {
        if (animationRef.current) {
          animationRef.current.revert(); // Cleanup animations on unmount
          ScrollTrigger.getAll().forEach((t) => t.kill()); // Ensure triggers are removed
        }
      };
    }, [isMobile, location.pathname]);
    
  return(
    <>
      <div className="allrounder">
        <div className="shiping_wrapper" id="section3">
          <div id="shiping-slide">
            <Swiper
              slidesPerView={2.5}
              centeredSlides={true}
              spaceBetween={180}
              loop={true}
              autoplay={false}
              navigation={false}
              // autoplay={{
              //   delay: 0,
              //   disableOnInteraction: false,
              //   pauseOnMouseEnter: true,
              // }}
              freeMode={false}
              speed={500}
              modules={[FreeMode, Autoplay, Navigation]}
              breakpoints={{
                0: {
                  spaceBetween: 20,
                  slidesPerView: 1.2,
                },
                768: {
                  spaceBetween: 40,
                  slidesPerView: 2.1
                },
                1100: {
                  spaceBetween: 180,
                  slidesPerView: 2.5
                },
              }}
              className="mySwiper"
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            >
              {slideContent.map((slide, index) => (
                <SwiperSlide key={index}>
                  <img src={slide.image} alt={`slide ${index + 1}`} />
                  <div className="shiping_content">
                    <div className="">
                      <h2>{slide.title}</h2>
                      {slide.description.map((desc, index) => (
                        <p key={index}>{desc}</p>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div id="section4">
          <ParallaxProvider>
            <div className="presence_wrap">
              <div className="transparent_layer"/>
              <div className="presence_content">
                <Parallax speed={-30}>
                  <div className="paralex_bg"/>
                </Parallax>
                <div className="container">
                  <section data-aos="fade-up" data-aos-delay="100">
                    <h2>Our Strong Presence in Costa Rica</h2>
                    <p>Costa Rica has become our primary market, where we have had the privilege of designing and building prefab villas for multiple luxury resorts and private retreats. Over the years, we've developed a deep connection with the country, understanding its unique landscape and demand for sustainable, high-end Bali prefab villas. To streamline our services, we've set up a dedicated logistics channel that ensures seamless prefabrication on Bali, shipping, and customs clearance in Costa Rica.</p>
                    <p>From local delivery to reassembly at the project site, we offer a full turn-key solution for selected models. Our ability to manage every aspect of the process, combined with our passion for sustainable, luxurious design, allows us to deliver beautiful, functional spaces that complement the natural beauty of Costa Rica.</p>
                    <Link to="/contact" className="simple_btn">Contact</Link>
                  </section>
                </div>
              </div>
            </div>
          </ParallaxProvider>
        </div>
      </div>
    </>
  )
}