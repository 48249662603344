import poster from "../../../assets/images/main-pic.jpeg"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
import mediaData from "./mediaData.json"

gsap.registerPlugin(ScrollTrigger);

export default function OurProcessSecond() {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const animationRef = useRef(null);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    setMedia(mediaData); // Assuming mediaData is an array
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1050);  // You can adjust the width based on your design needs
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  

  useLayoutEffect(() => {
    if (isMobile) return;

    // Cleanup previous animations
    if (animationRef.current) {
      animationRef.current.revert();
      // ScrollTrigger.getAll().forEach((t) => t.kill()); // Kill all triggers
    }

    animationRef.current = gsap.context(() => {
      const processDetail = document.querySelector("#processDetailSecond");

      if (!processDetail) return;

      gsap.to("#titleHeadSecond", {
        scrollTrigger: {
          trigger: "#titleHeadSecond",
          start: "bottom bottom",
          end: `+=${processDetail.offsetHeight + 200}`,
          scrub: true,
          pin: true,
          pinSpacing: false,
          markers: false,
        }
      });

      gsap.to("#processDetailSecond", {
        scrollTrigger: {
          trigger: "#processDetailSecond",
          start: "top center",
          end: "bottom top",
          scrub: true,
          markers: false,
          onEnter: () => gsap.to("#processDetailSecond", { y: 0, duration: 1 }),
          onLeaveBack: () => gsap.to("#processDetailSecond", { y: 0, duration: 1 })
        }
      });

      ScrollTrigger.refresh();
    });

    return () => {
      // if (animationRef.current) {
      //   animationRef.current.revert();
      //   ScrollTrigger.getAll().forEach((t) => t.kill());
      // }
      animationRef.current.revert();
    };
  }, [isMobile, location.pathname]);
  
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  
  return(
    <div className="second_orpros">
      <div className="process_title" id="titleHeadSecond">
        <img src={poster} alt="poster"/>
        <div className="container">
          <h2 data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">CUSTOM <br/>FURNISHINGS</h2>
        </div>
      </div>
      <div id="processDetailSecond" className="process_details">
        <div className="container">
          <div className="process_wrapper bordernone">
            <h3>FURNITURE & OTHER BESPOKE ELEMENTS</h3>
            <section>
              <p>We go beyond prefab villas, sourcing and creating custom furniture, hand-carved details, and high-end architectural elements to complete your project. Our collection includes bespoke furniture, stone carvings, ceramics, tiles, and statues—each crafted with precision and artistry.</p>
              <p>With a catalogue of curated designs and the ability to reproduce items from images or production files, we bring a tailored approach to luxury interiors. Every piece is chosen or created to harmonize beautifully with your space.</p>
            </section>
          </div>
          <div className="images_grid">
            <ul className="list-none flex flex-wrap">
              {media.length > 0 ? (
                media.map((item) => (
                  <li key={item.id} className="grid-item">
                    {item.type === "image" ? (
                      <Zoom><img src={require(`../../../assets/images/${item.src}`)} alt={item.alt} className="media-image" /></Zoom>
                    ) : (
                      <video controls className="media-video" poster={require(`../../../assets/images/${item.poster}`)}>
                        <source src={item.src} type="video/mp4" />
                      </video>
                    )}
                  </li>
                ))
              ) : (
                <p>Loading media...</p> // Debugging ke liye
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}