import "./ourProcess.scss"
import poster from "../../../assets/images/banner-image.jpg"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ProjectSlider from "./ProjectSlider";

gsap.registerPlugin(ScrollTrigger);

export default function OurProcess() {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const animationRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1050);  // You can adjust the width based on your design needs
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    // Refresh ScrollTrigger when route changes
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 500); // Add small delay to avoid race condition
  }, [location.pathname]); // Run on route change

  useLayoutEffect(() => {
    if (isMobile) return;

    // Cleanup previous animations
    if (animationRef.current) {
      animationRef.current.revert();
      // ScrollTrigger.getAll().forEach((t) => t.kill()); // Kill all triggers
    }

    animationRef.current = gsap.context(() => {
      const processDetail = document.querySelector("#processDetail");

      if (!processDetail) return;

      gsap.to("#titleHead", {
        scrollTrigger: {
          trigger: "#titleHead",
          start: "bottom bottom",
          end: `+=${processDetail.offsetHeight}`,
          scrub: true,
          pin: true,
          pinSpacing: false,
          markers: false,
        }
      });

      gsap.to("#processDetail", {
        scrollTrigger: {
          trigger: "#processDetail",
          start: "top center",
          end: "bottom top",
          scrub: true,
          markers: false,
          onEnter: () => gsap.to("#processDetail", { y: 0, duration: 1 }),
          onLeaveBack: () => gsap.to("#processDetail", { y: 0, duration: 1 })
        }
      });

      ScrollTrigger.refresh();
    });

    return () => {
      // if (animationRef.current) {
      //   animationRef.current.revert();
      //   ScrollTrigger.getAll().forEach((t) => t.kill());
      // }
      animationRef.current.revert();
    };
  }, [isMobile, location.pathname]);

  
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  
  return(
    <>
      <div className="process_title" id="titleHead">
        <video muted loop autoPlay playsInline poster={poster}>
          <source src="https://www.balihomedesigns.com/videos/banner-video.mp4" type="video/mp4"/>
        </video>
        <div className="container">
          <h2 data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">Our <br/>Process</h2>
        </div>
      </div>
      <div id="processDetail" className="process_details">
        <video id="videowrap" muted loop autoPlay playsInline>
          <source src="https://www.balihomedesigns.com/videos/practice_animation.mp4" type="video/mp4"/>
        </video>
        {/* <img src={PracticeAnimation} id="videowrap" alt="practice_animation"/> */}
        <div className="container">
          <div className="process_wrapper bordernone" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
            <h3>Design Process</h3>
            <section>
              <p>The journey of every project begins with the design of an authentic Balinese villa. At <span>Bali Home Designs</span>, we take great pride in designing all of our prefabricated structures <span>in-house</span>. Throughout the design process, we maintain close communication with our clients to ensure their vision is realized at every stage.</p>
              <p>We provide the following deliverables:</p>
              <ul className="list-none">
                <li>Architectural Drawings</li>
                <li>3D Renderings</li>
                <li>Detailed Onsite Assembly Manual</li>
              </ul>
            </section>
          </div>
        </div>
        <div className="container">
          <div className="process_wrapper" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
            <h3>Quality Materials & Certifications</h3>
            <section>
              <p>At Bali Home Designs, we are committed to using only the <span>highest-grade materials</span> for our builds. Our primary building materials include <span>Benkirai (Balau)</span>, <span>Teak</span>, and <span>Recycled Ironwood</span>, all of which are naturally resistant to termites. These woods are sourced and processed to meet <span>F27 structural grade or greater</span>. </p>
              <p>To ensure long-lasting durability, all is dried in-house using the internationally recognized <span>Kiln Drying Method</span>.</p>
              <p>We are <span>fully certified by SLVK</span> (Sistem Verifikasi Legalitas Kayu), which guarantees that the wood we use complies with <span>Indonesian local laws</span> and is eligible for the <span>V-Legal Certificate</span> required for export. This certification assures that all wood used in our structures is 100% <span>legal</span> and sustainably sourced.</p>
            </section>
          </div>
        </div>
        <div className="container">
          <div className="process_wrapper" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
            <h3>Prefabrication, Traditional Building Methods & Carving</h3>
            <section>
              <p>We strongly believe in the preservation of <span>Indonesian craftsmanship</span>. Our luxury villas are fully prefabricated in our factory in Bali, where we blend <span>traditional Balinese and Javanese building methods</span> with modern technology. Typically, our designs are elevated by the integration of <span>traditional wood carvings,</span> offering a unique blend of <span>artistry and functionality.</span></p>
              <p>Once the structure is completed, we <span>code and disassemble</span> it for easy transport, ensuring a streamlined assembly process once it reaches its final destination.</p>
            </section>
          </div>
        </div>
        <div className="container">
          <div className="process_wrapper" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
            <h3>SHIPPING & CLEARANCE</h3>
            <section>
              <p>Once your prefab villa is ready, we take care of the entire shipping process, ensuring each piece is carefully packed and shipped via our trusted logistics partners to any global destination.</p>
              <p>We also handle customs clearance, working with our dedicated agent in Costa Rica or partnering with a local clearance agent of your choice globally. We take care of all required documentation, ensuring that your prefab villa clears customs and arrives at its final destination without issue.</p>
            </section>
          </div>
        </div>
        <div className="container">
          <div className="process_wrapper" data-aos="fade-up" data-aos-offset="200" data-aos-delay="100">
            <h3>On-Site Assembly</h3>
            <section>
              <p>Our detailed assembly manual enables clients to reassemble the prefab structure <span>without prior experience</span> in working with Balinese wooden villas. With clear instructions and labeling, the assembly process is straightforward and manageable, ensuring a seamless installation experience.</p>
              <p>For projects in <span>Costa Rica</span> and surrounding countries, we collaborate with a trusted local partner who specializes in <span>turnkey solutions</span>, managing the entire project from assembly to final construction.</p>
            </section>
          </div>
        </div>
        <ProjectSlider/>
      </div>
    </>
  )
}