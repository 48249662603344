import "./ourProcess.scss"
import poster from "../../../assets/images/construction_image.jpg"
import chart from "../../../assets/images/chart.jpg"
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

gsap.registerPlugin(ScrollTrigger);

export default function OurConstruction() {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const animationRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1050);  // You can adjust the width based on your design needs
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const target = document.getElementById("client_wrap");

    if (!target) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          ScrollTrigger.refresh();
        }
      },
      { root: null, threshold: 0 }
    );

    observer.observe(target);

    return () => observer.disconnect();
  }, []);

  useLayoutEffect(() => {
    if (isMobile) return;

    // Cleanup previous animations
    if (animationRef.current) {
      animationRef.current.revert();
      // ScrollTrigger.getAll().forEach((t) => t.kill()); // Kill all triggers
    }

    animationRef.current = gsap.context(() => {
      const processDetail = document.querySelector("#processDetailConstruction");

      if (!processDetail) return;

      gsap.to("#titleHeadConstruction", {
        scrollTrigger: {
          trigger: "#titleHeadConstruction",
          start: "bottom bottom",
          end: `+=${processDetail.offsetHeight}`,
          scrub: true,
          pin: true,
          pinSpacing: false,
          markers: false,
        }
      });

      gsap.to("#processDetailConstruction", {
        scrollTrigger: {
          trigger: "#processDetailConstruction",
          start: "top center",
          end: "bottom top",
          scrub: true,
          markers: false,
          onEnter: () => gsap.to("#processDetailConstruction", { y: 0, duration: 1 }),
          onLeaveBack: () => gsap.to("#processDetailConstruction", { y: 0, duration: 1 })
        }
      });

      ScrollTrigger.refresh();
    });

    return () => {
      // if (animationRef.current) {
      //   animationRef.current.revert();
      //   ScrollTrigger.getAll().forEach((t) => t.kill());
      // }
      animationRef.current.revert();
    };
  }, [isMobile, location.pathname]);
  
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true
    });
  }, []);
  
  return(
    <>
      <div className="process_title" id="titleHeadConstruction">
        <img src={poster} alt="poster"/>
        <div className="container">
          <h2 data-aos="fade-right" data-aos-offset="200" data-aos-delay="100">Local construction <br/>of your prefab villa</h2>
        </div>
      </div>
      <div id="processDetailConstruction" className="process_details">
        <div className="container">
          <div className="process_wrapper bordernone">
            <h3>Costa Rica</h3>
            <section>
              <p>For clients building in Costa Rica, Bali Home Designs offers comprehensive support for the local construction of your prefab villa. We can assist with nearly all aspects of the build, ensuring a smooth and efficient process. Alternatively, if you have a trusted contractor, you are welcome to use their services for the assembly.</p>
            </section>
          </div>
        </div>
        <div className="container">
          <div className="process_wrapper">
            <h3>Other Regions</h3>
            <section>
              <p>For projects outside of Costa Rica, clients are responsible for organizing the local construction. Bali Home Designs will provide a detailed instruction manual and offer remote guidance or on-site assistance as needed to support a successful build.</p>
              <p>We are committed to making your prefab villa assembly as seamless as possible, no matter where you are building.</p>
            </section>
          </div>
        </div>
        <img id="processDetailImage" src={chart} alt="chart"/>
      </div>
    </>
  )
}