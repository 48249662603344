import {ParallaxProvider, Parallax } from "react-scroll-parallax"
import "./teamBanner.scss"
import minbanner from "../../../assets/images/teambg.jpg"
import { useGlobalState } from "../../../context/GlobalStateContext";
import Preloader from "../../../components/preloader/Preloader";
import { useEffect, useState } from "react";

export default function TeamBanner() {
  const { loading } = useGlobalState();
  const [bannerActive, setBannerActive] = useState(false);

  useEffect(() => {
    if (!loading) {
        const timer = setTimeout(() => {
            setBannerActive(true);
        }, 100); // Delay to allow the fade effect to be visible

        return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [loading]);
  return(
    <ParallaxProvider>
      {loading && <Preloader />}
      <div className={`team_banner ${bannerActive ? "active": ""}`}>
        <div className="container">
          <small><span>TEAM</span></small>
          <h1>
            <span className="mask">
              <span>Global standards,</span>
            </span>
            <span className="mask">
              <span>Indonesian craftmanship:</span>
            </span>
            <span className="mask">
              <span>prefab villas redefined</span>
            </span>
          </h1>
        </div>
        <figure id="inner_banner_image">
          <Parallax speed={-20}>
            <div className="paralex_bg"
              style={{
                backgroundImage: `url(${minbanner})`,
              }}
            />
          </Parallax>
        </figure>
      </div>
    </ParallaxProvider>
  )
}