import { Helmet } from "react-helmet-async";
import About from "./about/About";
import Banner from "./banner/Banner";
import GetInTouch from "./get-in-touch/GetInTouch";
import Newsletter from "./newsletter/Newsletter";
import ProjectsTwo from "./projects/ProjectsTwo";
import Shiping from "./shiping/Shiping";

export default function Home() {

  return(
    <>
      <Helmet>
        <title>Bali Home Designs | Luxury prefab Bali villas</title>
      </Helmet>
      <Banner/>
      <About/>
      <Shiping/>
      <ProjectsTwo projectTitle="Our Projects"/>
      <GetInTouch/>
      <Newsletter/>
    </>
  )
}