import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import logo from "../../assets/images/fav.svg";
import { Link, useParams } from "react-router-dom";
import projects from "./projectData.json";
import "./projects.scss";
import { useGlobalState } from "../../context/GlobalStateContext";
import Preloader from "../../components/preloader/Preloader";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import RelatedProjects from "../homepage/projects/RelatedProjects";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';

const ProjectDetail = () => {
  const { loading } = useGlobalState();
  const [bannerActive, setBannerActive] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setBannerActive(true);
      }, 100); // Delay to allow the fade effect to be visible

      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, [loading]);
  

  const { projectSlug } = useParams();
  const project = projects.find((p) => p.slug === projectSlug);

  // Dynamically import images for the project details
  const projectImage = project.projectDetailImage
    ? require(`../../assets/images/${project.projectDetailImage}`)
    : "../../assets/images/banner-image1.jpg"; // Default image if not found

  // Dynamically load the slides images based on the `projectDetailSlides` array
  const slides = project.projectDetailSlides.map((slide, index) => {
    const slideImage = require(`../../assets/images/${slide}`); // Dynamically import each slide image
    return (
      <li key={index}>
        <Zoom>
          <img src={slideImage} alt={project.name} />
        </Zoom>
      </li>
    );
  });

  const relatedProjects = projects.filter((p) => p.slug !== projectSlug);

  if (!project) {
    return <h2>Project Not Found</h2>;
  }
  

  return (
    <>
      <Helmet>
        <title>Bali Home Designs | {project.name}</title>
      </Helmet>
      {loading && <Preloader />}
      <div className="project_detail_all">
        <ParallaxProvider key={projectSlug}>
          <div
            className={`project_banner_wrap ${bannerActive ? "active" : ""}`}
            id="banner"
          >
            <figure>
              <Parallax speed={-20}>
                <img src={projectImage} alt="projectImage" />
              </Parallax>
            </figure>
            <div className="container">
              <div className="project_name">
                <small>
                  <span>{project.name}</span>
                </small>
                <h1>
                  <span>{project.tagline}</span>
                </h1>
              </div>
              {project.techStack && project.techStack.length > 0 && (
                <div className="project_info">
                  <h2>
                    <span>Project info</span>
                  </h2>
                  <ul className="list-none flex flex-wrap">
                    {Object.entries(project.techStack[0]).map(([key, value]) => (
                      <li key={key}>
                        <span>{key}</span> {value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </ParallaxProvider>
        <div className="project_details">
          <div className="container flex">
            <div className="project_details_titles">
              <h2>{project.name}</h2>
              {project.projectLink && (
                <Link to={project.projectLink} target="_blank" className="simple_btn white_bg">
                  Project Website
                </Link>
              )}
            </div>
            <section>
              <h3>{project.descriptionTitle}</h3>
              {project.description.map((description, index) => (
                <p key={index}>{description}</p>
              ))}
            </section>
          </div>
        </div>
        {project.projectDetailSlides && project.projectDetailSlides.length > 0 && (
        <div className="projectdetail_image">
          <div className="container">
            <ul className="list-none flex flex-wrap">
              {slides}
            </ul>
          </div>
        </div>
        )}
        <RelatedProjects projectTitle="Related Projects" projects={relatedProjects} />
        <img className="ab_logo" src={require("../../assets/images/fav.svg").default} alt="logo" />
      </div>
    </>
  );
};

export default ProjectDetail;