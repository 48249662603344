import {ParallaxProvider, Parallax } from "react-scroll-parallax"
import "./practiceBanner.scss"
import minbanner from "../../../assets/images/practice_bg.jpg"
import { useGlobalState } from "../../../context/GlobalStateContext";
import Preloader from "../../../components/preloader/Preloader";
import { useEffect, useState } from "react";

export default function PracticeBanner() {
  const { loading } = useGlobalState();

  const [bannerActive, setBannerActive] = useState(false);
  
    useEffect(() => {
      if (!loading) {
          const timer = setTimeout(() => {
              setBannerActive(true);
          }, 100); // Delay to allow the fade effect to be visible
  
          return () => clearTimeout(timer); // Cleanup timeout
      }
  }, [loading]);
  return(
    <ParallaxProvider>
      {loading && <Preloader />}
      <div id="practice" className={`practice_banner ${bannerActive ? "active": ""}`}>
        <div className="container">
          <small><span>Practice</span></small>
          <h1>
            <span className="mask">
              <span>We are designers, artisans,</span>
            </span>
            <span className="mask">
              <span>and creators—dedicated to </span>
            </span>
            <span className="mask">
              <span>crafting luxurious prefab </span>
            </span>
            <span className="mask">
              <span>villas & furniture</span>
            </span>
          </h1>
        </div>
        <figure id="inner_banner_image">
          <Parallax speed={-30}>
            {/* <div className="paralex_bg"
              style={{
                backgroundImage: `url(${minbanner})`,
              }}
            /> */}
            <img src={minbanner} alt="minbanner"/>
          </Parallax>
        </figure>
      </div>
    </ParallaxProvider>
  )
}